/*
 * TODO
 * This file should be cleaned out
 */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
    overscroll-behavior: none;
  }
}


* {
  font-family: var(--font-inter);
}

html, body {
  overflow: hidden;
  max-width: 100vw;
  max-height: 100dvh;
}

*::-webkit-scrollbar {
  display: none;
}

body {
  @apply font-sans bg-black text-white antialiased;
}

input[type='password'] {
  font-family: Verdana;
}

@layer utilities {
  .cf-shadow-transition {
    @apply transition-all duration-200 ease-in-out;
  }
}


.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.cf-container {
  @apply w-full md:max-w-[756px] lg:max-w-[936px] xl:max-w-[1080px];
}

.cf-page-wrapper {
  @apply relative min-h-screen w-full md:mx-auto md:max-w-[756px] md:border-l md:border-r md:border-white lg:max-w-[936px] xl:max-w-[1080px];
}

.cf-safe-area-margin {
  margin-top: env(safe-area-inset-top);
}

.cf-safe-area-padding {
  padding-top: env(safe-area-inset-top);
}

.cf-tile-tile-container {
  top: max(env(safe-area-inset-top), 32px);
}


@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
    z-index: 50;
  }
  75% {
    opacity: 1;
    z-index: 50;
  }
  100% {
    opacity: 0;
    z-index: 0;
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInOutAnimation {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.cf-transition-image-out {
  animation: fadeOutAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.cf-transition-image-in {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.cf-tool-tip {
  animation: fadeInOutAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

/* Subtile opening - closing animation */
.subtile-details {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

.subtile-details.opened {
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
}

/* Set variables for all modes */
:root {
  /* Set the background of the entire app */
  --ion-font-family: var(--font-inter)
  --ion-background-color: #000000;
  --ion-text-color: #ffffff;
  --ion-tab-bar-background: #000000;
  --ion-tab-bar-color: #999999;
  --ion-tab-bar-color-selected: #ffffff;
}

ion-card {
  --background: #333333;
  --color: #ffffff;
}

[data-nextjs-scroll-focus-boundary] {
  display: contents;
}


/* Classes applied for typography system created by Sebastian */

/* Typography - Heading */
.heading_xx_large {
  @apply font-sans font-semibold text-[40px] leading-[52px];
}

.heading_x_large {
  @apply font-sans font-semibold text-[36px] leading-[44px];
}

.heading_large {
  @apply font-sans font-semibold text-[32px] leading-[40px];
}

.heading_medium {
  @apply font-sans font-semibold text-[28px] leading-[36px];
}

.heading_small {
  @apply font-sans font-semibold text-[24px] leading-[32px];
}

.heading_x_small {
  @apply font-sans font-semibold text-[20px] leading-[28px];
}

/* Typography - Label */
.label_large {
  @apply font-sans font-semibold text-[18px] leading-[26px];
}

.label_medium {
  @apply font-sans font-semibold text-[16px] leading-[24px];
}

.label_small {
  @apply font-sans font-semibold text-[14px] leading-[18px];
}

.label_x_small {
  @apply font-sans font-semibold text-[12px] leading-[14px];
}

/* Typography - Paragraph */
.paragraph_large {
  @apply font-sans font-normal text-[18px] leading-[26px];
}

.paragraph_medium {
  @apply font-sans font-normal text-[16px] leading-[24px];
}

.paragraph_small {
  @apply font-sans font-normal text-[14px] leading-[18px];
}

.paragraph_x_small {
  @apply font-sans font-normal text-[11px] leading-[14px];
}

.link_black_medium {
  @apply font-sans font-medium text-[16px] leading-[24px] underline;
}

.link_black_small {
  @apply font-sans font-medium text-[12px] underline;
}

.link_green_medium {
  @apply font-sans font-medium text-[16px] leading-[24px] underline text-brand-primary;
}

.link_green_small {
  @apply font-sans font-medium text-[14px] leading-[20px] underline text-brand-primary;
}

/* Typography - Games */

/* playquizme wrapper */
.playquizme_wrapper {
  font-family: var(--font-itim);
}

.playquizme_header {
  @apply text-[30px] font-game;
}

.quizme_answerbutton {
  @apply grid place-content-center w-[90vw] h-[8vh] rounded-lg border-2 text-center shadow-quizmeBorder
}


.dynamic-screen-min-height {
  min-height: 100dvh;
}

.wobbly-pulse-animation {
  animation: wobbly-pulse 0.7s ease-in;
}

@keyframes wobbly-pulse {
  0%,
  100% {
    transform: scale(100%);
  }

  2%,
  12% {
    transform: scale(90%);
  }

  50%,
  85% {
    transform: scale(140%);
  }
}

.subtile-expand-clip-path {
  clip-path: polygon(100% 0, 0 100%, 100% 100%);
}

.games-clip-path {
  clip-path: polygon(100% 0, 0 100%, 100% 100%);
}

.subtile-image {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden !important;
  transform: translateZ(0) scale(1,1)!important;
}
.cf-splash-background {
  background: linear-gradient(127.43deg, #4F4FB5 3.46%, #8977E7 31.8%, #829DCE 45.11%, #7DBBBB 57.97%, #C9EC86 95.13%);
}

.interesting-clip-path {
  clip-path: polygon(50% 100%, 0 0, 100%  0);
}

@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  img[loading="lazy"] {
    clip-path: inset(0.6px)
  }
}

